<template>
    <div class="login">
      <vue-particles
      color="#7ec7fd"
      :particle-opacity="1"
      :particles-number="150"
      shape-type="circle"
      :particle-size="5"
      lines-color="#7ec7fd"
      :lines-width="2"
      :line-linked="true"
      :line-opacity="1"
      :lines-distance="150"
      :move-speed="3"
      :hover-effect="true"
      hover-mode="grab"
      :click-effect="true"
      click-mode="push"
      class="lizi"
    />
    <div class="login_form">
      <p>{{info.title}}</p>
          <el-form
            :model="loginForm"
            :rules="rules"
            ref="loginForm"
          >
            <el-form-item label="" prop="nickname" v-if="type=='register'">
              <el-input
                type="text"
                autocomplete="off"
                v-model="loginForm.nickname"
                prefix-icon="el-icon-user-solid"
                placeholder="用户昵称"
                maxlength="10"
              ></el-input>
            </el-form-item>
            <el-form-item label="" prop="username">
              <el-input
                type="text"
                autocomplete="off"
                v-model="loginForm.username"
                prefix-icon="el-icon-user-solid"
                placeholder="用户账号"
                maxlength="12"
              ></el-input>
            </el-form-item>
            <el-form-item label="" prop="password">
              <el-input
                type="password"
                autocomplete="off"
                v-model="loginForm.password"
                prefix-icon="el-icon-lock"
                placeholder="用户密码"
                maxlength="16"
              ></el-input>
            </el-form-item>
            <el-form-item label="" prop="code">
              <el-col :span="14">
              <el-input
                type="text"
                autocomplete="off"
                v-model="loginForm.code"
                prefix-icon="el-icon-key"
                placeholder="图形验证码"
                maxlength="4"
                @keyup.enter.native="type=='login'?login():register()"
              ></el-input></el-col>
              <el-col :span="9" :offset="1" style="height:40px">
                <img src="/api/code.php" ref="code" style="width:100%;height:40px" @click="updateCode">
              </el-col>
            </el-form-item>
            <el-form-item class="btns">
              <el-button type="primary" @click="type=='login'?login():register()" style="width:100%">{{info.submitName}}</el-button>
            </el-form-item>
            <el-form-item style="text-align:center;" class="btns">
              {{info.routeText}}<span @click="$router.push(type=='login'?'/register':'/login')" style="color:#1e9fff;cursor:pointer">{{info.routeName}}</span>
            </el-form-item>
          </el-form>
    </div>
  </div>
</template>

<script>
import { user } from '../api';
export default {
    name: 'Form',
    data() {
      var validateNickname = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("昵称不能为空"));
      } else if (value.search(/^[a-zA-Z0-9_\u4e00-\u9fa5]+$/) === -1) {
        callback(new Error("昵称只能是中文、英文、数字和下划线"));
      } else if(value.length<2||value.length>10){
        callback(new Error("昵称长度为2-10位"));
      } else {
        callback();
      }
    };
        var validateUsername = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("账号不能为空"));
      } else if (value.search(/^[a-zA-Z0-9]+$/) === -1) {
        callback(new Error("用户名只能是英文和数字"));
      } else if (value.length<6||value.length>12) {
        callback(new Error("用户名长度为6-12位"));
      } else {
        callback();
      }
    };
    var validatePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("密码不能为空"));
      } else if (value.length < 8 || value.length > 16) {
        callback(new Error("密码长度为8-16位"));
      } else if(value.indexOf(' ')>-1){
          callback(new Error('密码不能包含空格'));
      } else {
        callback();
      }
    };
    var validateCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("图形验证码不能为空"));
      } else if (value.search(/^[a-zA-Z0-9]+$/) === -1) {
        callback(new Error("图形验证码只能是英文和数字"));
      } else if (value.length !== 4) {
        callback(new Error("图形验证码为4位"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        nickname: "",
        username: "",
        password: "",
        time: "",
        code: ""
      },
      rules: {
        nickname: [
          { required: true, message: '昵称不能为空'},
          { validator: validateNickname, trigger: "blur" }
        ],
        username: [
          { required: true, message: '用户名不能为空'},
          { validator: validateUsername, trigger: "blur" },
        ],
        password: [
          { required: true, message: '密码不能为空'},
          { validator: validatePassword, trigger: "blur" },
        ],
        code: [
          { required: true, message: '验证码不能为空'},
          { validator: validateCode, trigger: "blur" },
        ],
      },
    };
    },
    props:['type'],
    computed: {
      info() {
          if(this.type=='login'){
            return {
              title: '竹水云代步 - 用户登录',
              submitName: '登录',
              routeName: '立即注册',
              routeText: '还没有账号？',
            }
      } else {
            return {
              title: '竹水云代步 - 用户注册',
              submitName: '注册',
              routeName: '立即登录',
              routeText: '已有账号？',
            }
      }
      }
    },
    mounted() {
      this.updateCode();
      // 粒子线条背景
    },
 methods: {
    // 登录
    login() {
      this.$refs.loginForm.validate((vaild) => {
        if (vaild) {
          user.login(this.loginForm).then(res => {
            if (res.code == 1) {
              this.$store.commit('setToken', res.token);
              this.$message({
                message: res.msg,
                type: "success"
              });
              this.$router.push("/home");
            } else {
              this.$message({
                message: '登录失败：' + res.msg,
                type: "error"
              });
            }
          });
          this.updateCode();
        }
      });
    },
    // 注册
    register() {
      this.$refs["loginForm"].validate((valid) => {
        if (valid) {
          user.register(this.loginForm).then(res => {
            if (res.code == 1) {
              this.$message({
                message: res.msg,
                type: "success"
              });
              this.$router.push("/login");
            } else {
              this.$message({
                message: '注册失败：' + res.msg,
                type: "error"
              });
            }
          });
          this.updateCode();
        } else {
          return false;
        }
      });
    },
    // 更新验证码
    updateCode(){
      this.loginForm.time = new Date().getTime();
      this.$refs.code.src = "https://step.zhuwater.cn/api/code.php?t=" + this.loginForm.time;
    },
  },
}
</script>

<style scoped lang='less'>
.login {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/login/bg-login.jpg");//背景图
  background: #1E9FFF;
  background-size: cover;
  overflow: auto;
  position: relative;
  .lizi{
    height: 99%;
  }
  /* &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  } */
  .login_form {
    width: 300px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 14px 20px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 0 10px #ddd;
    .el-form-item{
      margin-bottom: 15px !important;
        img{
           border: 1px solid #ddd;
      }
    }
    .btns {
        margin-bottom:0 !important;
    }
    p {
      margin: 0 0 14px 0;
      padding: 14px 0;
      color: #1E9FFF;
    font-size: 24px;
    text-align: center;
    font-weight: bold;
  }
  }
}
</style>